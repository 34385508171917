import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 根据路由设置标题
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next();
})

import AntDesign from 'ant-design-vue';
import * as AntDesignIcons from '@ant-design/icons-vue';

// 自定义JS

import Utils from '@/assets/js/utils'

const app = createApp(App);

Object.keys(AntDesignIcons).forEach((key) => {
    app.component(key, AntDesignIcons[key]);
});

// 自动注册组件
const requireComponents = require.context('./components', true, /\.vue/)
requireComponents.keys().forEach(fileName => {
    const componentInstance = requireComponents(fileName)
    app.component(componentInstance.default.name, componentInstance.default || componentInstance)
})

app.use(store)
app.use(router)
app.use(AntDesign)
app.config.globalProperties.$env = process.env
app.config.globalProperties.$utils = Utils;
app.mount('#app')

