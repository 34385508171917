// utils.js
export function getMenuItem(label, key, icon, children, theme) {
    return {
        key,
        icon,
        children,
        label,
        theme,
    };
}

export default {
    getMenuItem,
    // 其他方法
};
